import { MissionState } from '@way-lib/mission/interfaces/mission';
import { CGenMissionRow } from '@way-lib/models/Gen/CGenMissionRow';

export enum StatusAssignment {
  ALL = 'ALL',
  SELF = 'SELF',
  SUBCONTRACTED = 'SUBCONTRACTED',
}

export enum StatusLabel {
  RECEIVED = 'Reçu',
  SENT = 'Envoyé',
  EXPIRED = 'Expiré',
  REFUSED = 'Refusé',
  ACCEPTED = 'Accepté',
  DRAFT = 'Brouillon',
  UNCONFIRMED = 'Non confirmé',
  CONFIRMED = 'Confirmé',
  ASSIGNED = 'Attribué',
  UNASSIGNED = 'Non attribué',
  TO_INVOICE = 'À facturer',
  INVOICED = 'Facturé',
  STARTED = 'Démarré',
  IN_PLACE = 'En place',
  DEPARTURE = 'Départ',
  TO_CLOSE = 'À clôturer',
  NO_SHOW = 'No show',
  CANCELED = 'Annulé',
  SETTLED = 'Réglé',
}

export enum ButtonLabelType {
  START = 'START',
  CANCEL = 'CANCEL',
  ASSIGN = 'ASSIGN',
  RESUME = 'RESUME',
  CONFIRM = 'CONFIRM',
  INVOICE = 'INVOICE',
  MARK_AS_SETTLED = 'MARK_AS_SETTLED',
  FINISH = 'FINISH',
  CLOSE = 'CLOSE',
  DEPARTURE = 'DEPARTURE',
  IN_PLACE = 'IN_PLACE',
  ARRIVED_AT_DESTINATION = 'ARRIVED_AT_DESTINATION',
  PASSENGER_DROP_OFF = 'PASSENGER_DROP_OFF',
  IN_PLACE_DEPARTURE = 'IN_PLACE_DEPARTURE',
}

export enum ButtonLabel {
  START = 'Démarrer',
  CANCEL = 'Annuler',
  ASSIGN = 'Attribuer',
  RESUME = 'Reprendre',
  CONFIRM = 'Confirmer',
  INVOICE = 'Facturer',
  MARK_AS_SETTLED = 'Indiquer comme réglé',
  FINISH = 'Terminer',
  CLOSE = 'Clôturer',
  DEPARTURE = 'Départ',
  IN_PLACE = 'En place',
  ARRIVED_AT_DESTINATION = 'Arrivée à destination',
  PASSENGER_DROP_OFF = 'Dépose client',
  IN_PLACE_DEPARTURE = 'En place',
}

export type Status = {
  label: StatusLabel;
  cssClass: string;
  filterFn: (mission: CGenMissionRow) => boolean;
  weight: number;
  category: MissionState;
  assignmentType: StatusAssignment;
  buttonLabel?: ButtonLabelType;
};

export const MISSION_STATUSES: Status[] = [
  {
    label         : StatusLabel.RECEIVED,
    cssClass      : 'received',
    filterFn      : (mission) => mission.isReceived && mission.state === MissionState.PREPARATION,
    weight        : 1,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SELF,
    buttonLabel   : ButtonLabelType.START,
  },
  {
    label   : StatusLabel.SENT,
    cssClass: 'sent',
    filterFn: (mission) =>
      !mission.isCancelled &&
      mission.isSubcontracted &&
      mission.state === MissionState.PREPARATION &&
      !mission.childDtr.isExpired &&
      !mission.childDtr.isAccepted &&
      !mission.childDtr.isRefused,
    weight        : 1,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SUBCONTRACTED,
    buttonLabel   : ButtonLabelType.CANCEL,
  },
  {
    label   : StatusLabel.EXPIRED,
    cssClass: 'expired',
    filterFn: (mission) =>
      mission.isSubcontracted &&
      mission.state === MissionState.PREPARATION &&
      !mission.childDtr.isAccepted &&
      !mission.childDtr.isRefused &&
      mission.childDtr.isExpired,
    weight        : 1,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SUBCONTRACTED,
    buttonLabel   : ButtonLabelType.ASSIGN,
  },
  {
    label   : StatusLabel.REFUSED,
    cssClass: 'refused',
    filterFn: (mission) =>
      mission.isSubcontracted &&
      mission.state === MissionState.PREPARATION &&
      mission.childDtr.isRefused,
    weight        : 1,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SUBCONTRACTED,
    buttonLabel   : ButtonLabelType.ASSIGN,
  },
  {
    label   : StatusLabel.ACCEPTED,
    cssClass: 'accepted',
    filterFn: (mission) =>
      mission.isSubcontracted &&
      mission.state === MissionState.PREPARATION &&
      mission.childDtr.isAccepted,
    weight        : 1,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SUBCONTRACTED,
    buttonLabel   : ButtonLabelType.CANCEL,
  },
  {
    label   : StatusLabel.DRAFT,
    cssClass: 'draft',
    filterFn: (mission) =>
      !mission.isSubcontracted && mission.state === MissionState.PREPARATION && mission.isDraft,
    weight        : 2,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SELF,
    buttonLabel   : ButtonLabelType.RESUME,
  },
  {
    label   : StatusLabel.UNCONFIRMED,
    cssClass: 'unconfirmed',
    filterFn: (mission) =>
      !mission.isSubcontracted &&
      mission.state === MissionState.PREPARATION &&
      !mission.isConfirmed,
    weight        : 1,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SELF,
    buttonLabel   : ButtonLabelType.CONFIRM,
  },
  {
    label   : StatusLabel.CONFIRMED,
    cssClass: 'confirmed',
    filterFn: (mission) =>
      !mission.isSubcontracted &&
      !mission.isCancelled &&
      !mission.isReceived &&
      mission.state === MissionState.PREPARATION &&
      mission.isConfirmed,
    weight        : 2,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SELF,
    buttonLabel   : ButtonLabelType.START,
  },
  {
    label   : StatusLabel.ASSIGNED,
    cssClass: 'assigned',
    filterFn: (mission) =>
      !mission.isSubcontracted &&
      !mission.isCancelled &&
      !mission.isReceived &&
      mission.state === MissionState.PREPARATION &&
      mission.isAssigned,
    weight        : 1,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SELF,
  },
  {
    label   : StatusLabel.UNASSIGNED,
    cssClass: 'unassigned',
    filterFn: (mission) =>
      !mission.isSubcontracted && !mission.isCancelled && mission.state === MissionState.PREPARATION && !mission.isAssigned,
    weight        : 3,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.SELF,
    buttonLabel   : ButtonLabelType.ASSIGN,
  },
  {
    label   : StatusLabel.TO_INVOICE,
    cssClass: 'to-invoice',
    filterFn: (mission) =>
      !mission.isSubcontracted && mission.state === MissionState.POST && mission.isBillable,
    weight        : 1,
    category      : MissionState.POST,
    assignmentType: StatusAssignment.SELF,
    buttonLabel   : ButtonLabelType.INVOICE,
  },
  {
    label   : StatusLabel.INVOICED,
    cssClass: 'invoiced',
    filterFn: (mission) =>
      !mission.isSubcontracted &&
      mission.state === MissionState.POST &&
      mission.isBilled &&
      !mission.isPayed,
    weight        : 1,
    category      : MissionState.POST,
    assignmentType: StatusAssignment.SELF,
    buttonLabel   : ButtonLabelType.MARK_AS_SETTLED,
  },
  {
    label         : StatusLabel.STARTED,
    cssClass      : 'started',
    filterFn      : (mission) => mission.state === MissionState.ONGOING && mission.isStarted,
    weight        : 1,
    category      : MissionState.ONGOING,
    assignmentType: StatusAssignment.ALL,
    buttonLabel   : ButtonLabelType.IN_PLACE_DEPARTURE,
  },
  {
    label         : StatusLabel.IN_PLACE,
    cssClass      : 'in-place',
    filterFn      : (mission) => mission.state === MissionState.ONGOING && mission.isAtLocation,
    weight        : 1,
    category      : MissionState.ONGOING,
    assignmentType: StatusAssignment.ALL,
  },
  {
    label         : StatusLabel.DEPARTURE,
    cssClass      : 'departure',
    filterFn      : (mission) => mission.state === MissionState.ONGOING && mission.hasDeparted,
    weight        : 1,
    category      : MissionState.ONGOING,
    assignmentType: StatusAssignment.ALL,
    buttonLabel   : ButtonLabelType.DEPARTURE,
  },
  {
    label         : StatusLabel.TO_CLOSE,
    cssClass      : 'to-close',
    filterFn      : (mission) => mission.state === MissionState.ONGOING && mission.toClose,
    weight        : 1,
    category      : MissionState.ONGOING,
    assignmentType: StatusAssignment.ALL,
    buttonLabel   : ButtonLabelType.CLOSE,
  },
  {
    label         : StatusLabel.NO_SHOW,
    cssClass      : 'no-show',
    filterFn      : (mission) => mission.state === MissionState.ONGOING && mission.hasNoShow,
    weight        : 1,
    category      : MissionState.ONGOING,
    assignmentType: StatusAssignment.ALL,
    buttonLabel   : ButtonLabelType.CLOSE,
  },
  {
    label         : StatusLabel.CANCELED,
    cssClass      : 'canceled',
    filterFn      : (mission) => mission.state === MissionState.PREPARATION && mission.isCancelled,
    weight        : 2,
    category      : MissionState.PREPARATION,
    assignmentType: StatusAssignment.ALL,
  },
  {
    label         : StatusLabel.SETTLED,
    cssClass      : 'settled',
    filterFn      : (mission) => mission.state === MissionState.POST && mission.isPayed,
    weight        : 1,
    category      : MissionState.POST,
    assignmentType: StatusAssignment.ALL,
  },
];

export function getApplicableStatuses(mission: CGenMissionRow): Status[] {
  return MISSION_STATUSES.filter((statusFilter) => statusFilter.filterFn(mission)).sort(
    (a, b) => b.weight - a.weight,
  );
}
